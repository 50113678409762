import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import Sidebar from "../../hoc/sidebar";

const Result = ({
  token,
  onSetThemes,
  onSetCurrentTheme,
  onSetQuestions,
  onLogout,
  error,
  success,
  sendResult,
}) => {
  // -------------------- GET RESULT ------------------
  const countResult = JSON.parse(sessionStorage.getItem("result"));
  const general = JSON.parse(sessionStorage.getItem("general"));
  const answers = JSON.parse(sessionStorage.getItem("answers"));
  const timer = JSON.parse(sessionStorage.getItem("timer"));
  // ------------- GET HISTORY -------------------
  const history = useHistory();
  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };
  // -------------- LOGOUT FUNCTION ----------------
  const logoutUser = () => {
    onLogout(token);
    history.push("/");
  };

  // -------- INFINITE SENT RESULT -------------
  React.useEffect(() => {
    let infinite;
    if (error !== null) {
      infinite = setInterval(() => {
        let leftTime = 20 * 60 - timer;
        sendResult({
          info: {
            type: general.type,
            theme_id: general.theme,
            result: countResult.result.length,
            time: leftTime,
            data_question: JSON.stringify(answers),
            count_question: countResult.count.length,
          },
          token,
        });
      }, 5000);
    }
    success !== null && clearInterval(infinite);
  }, [error, success]);

  const definedType =
    general?.type === "all_thema"
      ? (countResult?.count * 90) / 100
      : countResult?.count - 2;

  return (
    <div className="row">
      <Sidebar />
      <main class="content">
        <div class="content__inner">
          <div class="result page-block">
            <h1 class="result__title title">
              {countResult?.result?.length < definedType
                ? "Іспит не зданий"
                : "Іспит успішно пройдений"}
            </h1>
            {countResult?.result?.length < definedType ? (
              <div class="result__item result__item--danger">
                Ваш бал:{" "}
                <span>
                  {countResult?.result?.length}/{countResult?.count}
                </span>
              </div>
            ) : (
              <div class="result__item result__item--success">
                Ваш бал:{" "}
                <span>
                  {countResult?.result?.length}/{countResult?.count}
                </span>
              </div>
            )}
            <div className="result-btn-container" style={{ display: "flex" }}>
              <NavLink to="/test">
                <button class="btn register__btn">Правильні відповіді</button>
              </NavLink>
              <NavLink to="/" onClick={resetTest} style={{ margin: "0 15px" }}>
                <button class="btn register__btn">На головну</button>
              </NavLink>
              <p onClick={logoutUser}>
                <button class="btn register__btn">Вийти з програми</button>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Result;
