
import React, { useRef, useEffect, useState } from 'react';
import MaskedInput from 'react-maskedinput'


const Field = (props) => {
    // ----------------- SET REFS ------------------
    const filed = useRef(null);
    // -------------- CHANGE PLACEHOLDER ---------------
    //   --------------(MOVE TO TOP) ---------------
    const movePlaceholder = () => {
        filed.current.classList.add("active");
    };
    //  ------------- (MOVE BACK) ---------------
    const backPlaceholder = (e) => {
        props.onBlur(e);
        if (props.value.length > 0) {
        } else {
            filed.current.classList.remove("active");
        }

    };
    // ------------------ KEEP TRACK LENGTH INPUT ----------------------
    useEffect(() => {
        if (props.value.length > 0) {
            filed.current.classList.add("active");
        }
    }, [filed]);


    return (
        <div className="input register__input">
            {props.mask
                ? <MaskedInput
                    className="input__input"
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    onChange={props.onChange}
                    onFocus={movePlaceholder}
                    onBlur={backPlaceholder}
                    mask={props.mask}
                    value={props.value}
                    min="18"
                />
                : <input
                    className="input__input"
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    onChange={props.onChange}
                    onFocus={movePlaceholder}
                    onBlur={backPlaceholder}
                    value={props.value}
                />}
            <label htmlFor={props.id} className="input__placeholder" ref={filed}>
                {props.text}
            </label>
            {props.errors && props.touched
                ? <div className={"field__error"}>{props.errors} {props.text}</div>
                : null}
        </div>
    )
}
export default Field;