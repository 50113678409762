import { connect } from "react-redux";
import React from "react";
import Ride from "../components/Ride/Ride";
import Actions from "../redux/actions/index";

const RideHOC = (props) => {
    return <Ride {...props} />;
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    categories: state.ride.categories,
    instructors: state.ride.instructors,
    calendar: state.ride.calendar,
    isSend: state.ride.isSend,
    isFetching: state.ride.isFetching
});

const mapDispatchToProps = (dispatch) => ({
    getData: (data) => dispatch(Actions.ride.getRidesDataRequest(data)), 
    getCalendar: (data, userId) => dispatch(Actions.ride.getRideCalendarRequest(data, userId)), 
    onSend: (data, token) => dispatch(Actions.ride.sendRideRequest(data, token)),
    resetData: () => dispatch(Actions.ride.resetRidesCalendarSuccess()),
 
})

export default connect(mapStateToProps,mapDispatchToProps)(RideHOC);