export default {
    GET_RIDES_DATA_REQUEST : "GET_RIDES_DATA_REQUEST",
    GET_RIDES_DATA_SUCCESS: " GET_RIDES_DATA_SUCCESS",

    GET_RIDES_CALENDAR_REQUEST : "GET_RIDES_CALENDAR_REQUEST",
    GET_RIDES_CALENDAR_SUCCESS: " GET_RIDES_CALENDAR_SUCCESS",

    RESET_RIDES_DATA: "RESET_RIDES_DATA",
    
    SEND_RIDES_REQUEST: "RIDE_REQUEST",
    SEND_RIDES_SUCCESS: " SEND_RIDE_SUCCESS"
  };
  