import React from "react";
import { connect } from "react-redux";
import General from "../components/General/General";
import Actions from "../redux/actions/index";

export const GeneralHOC = (props) => {
  return <General {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  themes: state.general.themes,
  currentTheme: state.general.currentTheme,
  user: state.auth.user,
  crypt: state.test.crypt,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  onGetUser: (data) => dispatch(Actions.auth.getUserRequest(data)),
  // ---------------- THEME --------------------
  onGetThemes: (data) => dispatch(Actions.general.getThemesRequest(data)),
  onCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  // ----------------------- GET QUESTIONS -------------------------------
  onGetQuestions: (data) => dispatch(Actions.test.getQuestionsRequest(data)),
  onGetAllQuestions: (data) =>
    dispatch(Actions.test.getAllQuestionsRequest(data)),
  onGetExamQuestions: (data) =>
    dispatch(Actions.test.getExamQuestionsRequest(data)),
  onGetCrypt: (data) => dispatch(Actions.test.getCryptRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralHOC);
