import auth from "./auth";
import general from "./general";
import test from "./test";
import result from "./result";
import myTests from "./myTests";
import popup from "./popup";
import ride from "./ride"
export default {
  auth,
  general,
  test,
  result,
  myTests,
  popup,
  ride
};
