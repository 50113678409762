import types from "../types/index";

const initialState = {
  token: null,
  user: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.auth.LOGIN_SUCCESS:
      return {
        ...state,
        token: payload,
      };
    case types.auth.DEMO_LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.access_token,
        user: payload.user
      };
    case types.auth.GET_USER_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

export default reducer;
