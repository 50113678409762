import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetTests({ payload }) {
  const response = yield API.myTests.getTests(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося отримати дані"));
    } else {
      yield put(Actions.myTests.getTestsSuccess(response.data.result));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося отримати дані"));
  }
}
