import types from "../types/index";

const initialState = {
  questions: null,
  crypt: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.test.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: payload,
      };
    case types.test.GET_CRYPT_SUCCESS:
      return {
        ...state,
        crypt: payload,
      };
    default:
      return state;
  }
};

export default reducer;
