import types from "../types/index";

const initialState = {
  themes: null,
  currentTheme: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.general.GET_THEMES_SUCCESS:
      return {
        ...state,
        themes: payload,
      };
    case types.general.CURRENT_THEME:
      return {
        ...state,
        currentTheme: payload,
      };
    default:
      return state;
  }
};

export default reducer;
