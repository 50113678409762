import types from "../types/index";

const getQuestionsRequest = (payload) => ({
  type: types.test.GET_QUESTIONS_REQUEST,
  payload,
});

const getQuestionsSuccess = (payload) => ({
  type: types.test.GET_QUESTIONS_SUCCESS,
  payload,
});

const getAllQuestionsRequest = (payload) => ({
  type: types.test.GET_ALL_QUESTIONS_REQUEST,
  payload,
});

const getExamQuestionsRequest = (payload) => ({
  type: types.test.GET_EXAM_QUESTIONS_REQUEST,
  payload,
});

const getQuestionsFromSessionRequest = (payload) => ({
  type: types.test.GET_QUESTIONS_FROM_SESSION_REQUEST,
  payload,
});

const getCryptRequest = (payload) => ({
  type: types.test.GET_CRYPT_REQUEST,
  payload,
});

const getCryptSuccess = (payload) => ({
  type: types.test.GET_CRYPT_SUCCESS,
  payload,
});

export default {
  getQuestionsRequest,
  getQuestionsSuccess,
  getAllQuestionsRequest,
  getExamQuestionsRequest,
  getQuestionsFromSessionRequest,
  getCryptRequest,
  getCryptSuccess,
};
