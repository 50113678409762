import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchLogin({ payload }) {
  const response = yield API.auth.login(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вірний логін або пароль"));
    } else {
      yield sessionStorage.setItem("token", response.data.access_token);
      yield put(Actions.auth.loginSuccess(response.data.access_token));
    }
  } else {
    yield put(Actions.popup.messageError("Не вірний логін або пароль"));
  }
}

export function* watchDemoLogin({ payload }) {

  const response = yield API.demoAuth.accessDemo(payload);
  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка реєстрації"));
    } else {
      yield sessionStorage.setItem("token", response.data.access_token);
      yield put(Actions.auth.demoLoginSuccess(response.data));
      yield sessionStorage.setItem("user", JSON.stringify(response.data.user));
    }
  } else {
    yield put(Actions.popup.messageError("Не вірний логін або пароль"));
  }
}


export function* watchLogout({ payload }) {
  const response = yield API.auth.logout(payload);

  if (response.status === 200) {
    if (response.data.status === "Не вдалося вийти") {
      yield put(Actions.popup.messageError("Error"));
    } else {
      yield sessionStorage.clear("token");
      yield put(Actions.auth.loginSuccess(null));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося вийти"));
  }
}

export function* watchGetUser({ payload }) {
  const response = yield API.auth.getUser(payload);

  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Користувача не знайдено"));
    } else {
      yield sessionStorage.setItem("user", JSON.stringify(response.data));
      yield put(Actions.auth.getUserSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Користувача не знайдено"));
  }
}
