import React, { useEffect, useState } from "react";
import Sidebar from "../../hoc/sidebar";
import Field from "../common/Filed/Field";
import { Formik, Field as FieldFormik } from 'formik';
import { validateEmail, validateTel, validateText, validateSelect } from "../../validators/validators";
import { NavLink, useHistory } from "react-router-dom";
import ThanksMessage from "../common/ThanksMessage/ThanksMessage";


const DemoAuth = ({
    token,
    setLogin,
    onDemoLogin,
    user,
}) => {
    // ----------- Local --------------
    const [isRegistrated, setIsRegistrated] = useState(false);
    const history = useHistory(null);

    // ----------- KEEP TRACK LOGIN --------------
    // // ----------- KEEP TRACK LOGIN --------------
    useEffect(() => {
        if (isRegistrated) {
            if (!token) {
               setTimeout(() => {
                setLogin(sessionStorage.getItem("token"));
               }, 4000)
            }
        }
    }, [token, isRegistrated]);

    return (
        <div className="row">
            <Sidebar />
            <main className="content">
                <div class="content__inner jc-center">
                    {
                        isRegistrated
                            ? <ThanksMessage title="Дякуємо за реєстрацію" text="Ви успішно зареєтрувалися!" />

                            : <> <h1 className="register__title title">Отримати <span>ДЕМО доступ</span></h1>
                                <p className="register__description">Заповніть форму реєстрації</p>
                                <Formik
                                    initialValues={{
                                        last_name: '',
                                        first_name: '',
                                        email: '',
                                        phone: '',
                                        region: '',
                                        city: '',
                                        answer_type: ''
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        onDemoLogin(values)
                                        setIsRegistrated(true)

                                    }}>

                                    {({ errors, touched, ...props }) => (

                                        <form className="register page-block" onSubmit={props.handleSubmit}>
                                            <FieldFormik
                                                type="text"
                                                name="last_name"
                                                text="прізвище"
                                                id="last_name"
                                                value={props.values.last_name}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                component={Field}
                                                validate={validateText}
                                                errors={errors.last_name}
                                                touched={touched.last_name}
                                            />

                                            <FieldFormik
                                                type="text"
                                                name="first_name"
                                                text="ім'я"
                                                id="first_name"
                                                value={props.values.first_name}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                component={Field}
                                                validate={validateText}
                                                errors={errors.first_name}
                                                touched={touched.first_name} />

                                            <FieldFormik
                                                type="email"
                                                name="email"
                                                text="email"
                                                id="email"
                                                value={props.values.email}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                component={Field}
                                                validate={validateEmail}
                                                errors={errors.email}
                                                touched={touched.email} />

                                            <FieldFormik
                                                type="tel"
                                                name="phone"
                                                text="номер телефону"
                                                id="phone"
                                                value={props.values.phone}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                mask="+380 (111) 111 111"
                                                component={Field}
                                                validate={validateTel}
                                                errors={errors.phone}
                                                touched={touched.phone} />

                                            <FieldFormik type="text"
                                                name="region"
                                                text="область"
                                                id="region"
                                                value={props.values.region}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                component={Field}
                                                validate={validateText}
                                                errors={errors.region}
                                                touched={touched.region} />

                                            <FieldFormik
                                                type="text"
                                                name="city"
                                                text=" місто"
                                                id="city"
                                                value={props.values.city}
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                component={Field}
                                                validate={validateText}
                                                errors={errors.city}
                                                touched={touched.city} />

                                            <FieldFormik
                                                className="input__input"
                                                name="answer_type"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                as="select"
                                                component="select"
                                                validate={validateSelect}>

                                                <option selected hidden > Оберіть один із варіантів</option>
                                                <option value="i_have_driving_license">Я маю посвідчення водія</option>
                                                <option value="i_study">Я навчаюся в автошколі</option>
                                                <option value="i_plan_study">Я планую навчатися в автошколі</option>

                                            </FieldFormik>

                                            {errors.answer_type && touched.answer_type
                                                ? <div className={"field__error"}>{errors.answer_type}</div>
                                                : null}

                                            <div className="register__footer">

                                                <NavLink to="/" className="register__link">На головну</NavLink>

                                                <button disable={props.dirty} className="btn register__btn" type="submit">
                                                    Зареєструватися
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Formik></>
                    }
                </div>
            </main>
        </div>
    );
};

export default DemoAuth;
