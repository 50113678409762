export const validateEmail = (value) => {
    let errorMessage;
    if (value.length === 0) {
        errorMessage = "Введіть "
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        errorMessage = 'Некоректний ';
    }
    return errorMessage;
};
export const validateTel = (value) => {
    let errorMessage;
    if (value.length === 0) {
        errorMessage = "Введіть"
    } else if (value.replace(/[^\d;]/g, '').length < 12 ){
        errorMessage = "Некоректний"
    }
    return errorMessage;
};
export const validateText = (value) => {
    let errorMessage;
    if (value.length === 0) {
        errorMessage = `Введіть`
    } 
    return errorMessage;
};
export const validateSelect = (value) => {
    let errorMessage;
    if (!value) {
        errorMessage = "Оберіть один із запропонованих варіантів";
    } 
    return errorMessage;
};