import { apiFunctions } from "./apiFunctions";

export const test = {
  getQuestions: (data) =>
    apiFunctions.getFunc(data.id, "/auth/get-questions-themes-crypt", data.token),
  getAllQuestions: (data) =>
    apiFunctions.getFunc(
      data.id,
      "/auth/get-all-questions-themes-crypt",
      data.token
    ),
  getExamQuestions: (data) =>
    apiFunctions.getFunc(null, "/auth/get-questions-exam-crypt", data),
  getQuestionsFromSession: (data) =>
    apiFunctions.getFunc(null, "/auth/get-question-from-session-crypt", data),
  getCrypt: (data) => apiFunctions.getFunc(null, "/auth/exam-crypt", data),
};
