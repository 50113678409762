export default {
  GET_QUESTIONS_REQUEST: "GET_QUESTIONS_REQUEST",
  GET_QUESTIONS_SUCCESS: "GET_QUESTIONS_SUCCESS",

  GET_ALL_QUESTIONS_REQUEST: "GET_ALL_QUESTIONS_REQUEST",

  GET_EXAM_QUESTIONS_REQUEST: "GET_EXAM_QUESTIONS_REQUEST",

  GET_QUESTIONS_FROM_SESSION_REQUEST: "GET_QUESTIONS_FROM_SESSION_REQUEST",

  GET_CRYPT_REQUEST: "GET_CRYPT_REQUEST",
  GET_CRYPT_SUCCESS: "GET_CRYPT_SUCCESS",
};
