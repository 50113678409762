import React from "react";
import { connect } from "react-redux";
import MyTests from "../components/MyTests/MyTests";
import Actions from "../redux/actions/index";

export const MyTestsHOC = (props) => {
  return <MyTests {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  my_tests: state.myTests.my_tests,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  onGetTests: (data) => dispatch(Actions.myTests.getTestsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyTestsHOC);
