import types from "../types/index";

const sendResultRequest = (payload) => ({
  type: types.result.SEND_RESULT_REQUEST,
  payload,
});

export default {
  sendResultRequest,
};
