import types from "../types/index";

const initialState = {
  my_tests: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.myTests.GET_TESTS_SUCCESS:
      return {
        ...state,
        my_tests: payload,
      };
    default:
      return state;
  }
};

export default reducer;
