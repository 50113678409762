import React from "react";
import { connect } from "react-redux";
import Actions from "../redux/actions/index";
import Popup from "../components/Popup/Popup";

export const PopupHOC = (props) => {
  return <Popup {...props} />;
};

const mapStateToProps = (state) => ({
  error: state.popup.error,
  success: state.popup.success,
});

const mapDispatchToProps = (dispatch) => ({
  messageError: (data) => dispatch(Actions.popup.messageError(data)),
  messageSuccess: (data) => dispatch(Actions.popup.messageSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupHOC);
