import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchSendResult({ payload }) {
  let response = yield API.result.sendResult(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка надсилання"));
    } else {
      yield put(Actions.popup.messageSuccess("Успішно надіслано"));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка надсилання"));
  }
}
