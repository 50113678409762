import types from "../types/index";

const getTestsRequest = (payload) => ({
  type: types.myTests.GET_TESTS_REQUEST,
  payload,
});

const getTestsSuccess = (payload) => ({
  type: types.myTests.GET_TESTS_SUCCESS,
  payload,
});

export default {
  getTestsRequest,
  getTestsSuccess,
};
