import React, { useEffect, useState } from "react";
import Sidebar from "../../hoc/sidebar";
import { NavLink, useHistory } from "react-router-dom";
const Test = (props) => {
  // ---------------- GET PROPS -------------------------
  const {
    questions,
    token,
    sendResult,
    getQuestionsFromSession,
    onSetThemes,
    onSetCurrentTheme,
    onSetQuestions,
    onLogout,
    crypt,
    onGetCrypt,
  } = props;
  // ---------------- GET TYPE TEST ------------------
  const general = JSON.parse(sessionStorage.getItem("general"));
  // --------------- GET USER --------------------------------
  const user = JSON.parse(sessionStorage.getItem("user"));
  // -------------- GET TIME --------------------
  const currentTime = JSON.parse(sessionStorage.getItem("timer"));
  // -------------- GET ANSWERS ----------------------
  const currentAnswers = JSON.parse(sessionStorage.getItem("answers"));
  // ------------ GET CURRENT NUMBER ------------------------
  const currNumbSess = JSON.parse(sessionStorage.getItem("current"));
  // ------------ GET CURRENT RESULT --------------------------
  const currResSess = JSON.parse(sessionStorage.getItem("result"));
  // ------------- GET GREEN QUESTIONS ----------------------
  const greenQ = JSON.parse(sessionStorage.getItem("greenQ"));
  // ------------- GET RED QUESTIONS ----------------------
  const redQ = JSON.parse(sessionStorage.getItem("redQ"));
  // ----------------- GET LOCATION --------------------
  const location = useHistory(null);
  // ----------------- SET CURRUNT QUESTION ---------------
  const [currQuest, setCurrQuest] = useState(null);
  // ----------------- SET CURRENT ANSWER ---------------
  const [answer, setAnswer] = useState("");
  // ----------------- SET CURRENT NUMBER ----------------
  const [number, setNumber] = useState(null);
  // ----------------- SET STATE FOR COMMON QUIESTION ------------------
  const [commonQuest, setCommonQuest] = useState(null);
  // ----------------- SET CURRENT NUMBER ----------------
  const [currNumb, setCurrNumb] = useState(1);
  // ----------------- SET ARRAY ANSWERS -------------
  const [arrAnswers, setArrAnswers] = useState([]);
  // --------------- SET COUNTER -----------------
  const [counter, setCounter] = useState(20 * 60);
  const [counterText, setCounterText] = useState(null);
  // -------------- SET ALL INFO ABOUT TEST ------------
  const [info, setInfo] = useState([]);
  // -------------- SET RESULT --------------------
  const [countResult, setCountResult] = useState([]);
  // ------------ SET ARRAY QUESTIONS --------------
  const [questArray, setQuestArray] = useState([]);
  // -------------- SET CORRECT QUESTIONS ----------------
  const [correctQuestions, setCorrectQuestions] = useState([]);
  // -------------- SET UNCORRECT QUESTIONS ---------------
  const [uncorrectQuestions, setUncorrectQuestions] = useState([]);
  // ---------------- SET QUESTIONS -------------------
  useEffect(() => {
    if (questions !== null && token) {
      setCommonQuest(questions);
      if (currNumbSess) {
        const getQue = questions.filter((q) => q.id === currNumbSess.id);
        setCurrNumb(currNumbSess.number);
        setNumber(currNumbSess.id);
        setCurrQuest(getQue[0]);
      } else {
        setCurrQuest(questions[0]);
        setNumber(questions[0].id);
        setCurrNumb(1);
      }
    }
  }, [questions, token, currNumbSess]);
  // -------------- SET CHECKED QUESTION ------------------
  const onChangeQuestion = (id, index) => {
    const current = commonQuest?.filter((q) => q.id == id);
    const checked = current[0]?.answers?.filter((a) =>
      arrAnswers.includes(a.id)
    );
    if (info?.length === commonQuest?.length) {
      setCurrQuest(...current);
      setCurrNumb(index + 1);
      setNumber(current[0].id);
      sessionStorage.setItem(
        "current",
        JSON.stringify({ number: index + 1, id: current[0].id })
      );
    }
    if (checked.length !== 0) {
      return;
    }
    setCurrQuest(...current);
    setCurrNumb(index + 1);
    setNumber(current[0].id);
    sessionStorage.setItem(
      "current",
      JSON.stringify({ number: index + 1, id: current[0].id })
    );
  };
  // ------------- SET CHECKED ANSWERS ------------------
  const onChangeAnswers = (element) => {
    setAnswer(element.id);
  };
  // ----------------- CHECK ANSWER -----------------
  const checkAswer = (e) => {
    e.preventDefault();
    const arrNumb = currQuest?.answers.filter((q) => q.id === answer);
    if (!answer) {
      return;
    }
    if (!currQuest) {
      return;
    }
    if (arrNumb.length === 0) {
      return;
    }
    // -------------- SET QUESTION_ID TO ARRAY -----------
    setQuestArray([...questArray, arrNumb[0].question_id]);
    // --------------- TO NEXT NUMBER --------------------
    let numberTop = currNumb + 1;
    let current = commonQuest?.find(
      (q, index) => index + (numberTop - currNumb) === numberTop
    );
    let nextQuest = current?.answers.filter((q) => arrAnswers.includes(q.id));
    if (nextQuest?.length > 0 || commonQuest.length === currNumb) {
      const findQuest = commonQuest.find((q) => !questArray.includes(q.id));
      if (findQuest === undefined) {
        current = commonQuest[0];
        numberTop = 1;
      } else {
        if (findQuest.id === currQuest.id) {
          current = commonQuest.find(
            (q) => !questArray.includes(q.id) && q.id !== currQuest.id
          );
          numberTop = commonQuest.indexOf(current) + 1;
        } else {
          current = findQuest;
          numberTop = commonQuest.indexOf(current) + 1;
        }
      }
    }
    setCurrQuest(current);
    setCurrNumb(numberTop);
    setNumber(current?.length === 0 ? 1 : current?.id);
    sessionStorage.setItem(
      "current",
      JSON.stringify({
        number: numberTop,
        id: current?.length === 0 ? 1 : current?.id,
      })
    );
    // _----------- SET COLOR ------------------
    if (arrNumb[0].correct_answer === 1) {
      setCorrectQuestions([...correctQuestions, currQuest.id]);
      sessionStorage.setItem(
        "greenQ",
        JSON.stringify([...correctQuestions, currQuest.id])
      );
    } else {
      setUncorrectQuestions([...uncorrectQuestions, currQuest.id]);
      sessionStorage.setItem(
        "redQ",
        JSON.stringify([...uncorrectQuestions, currQuest.id])
      );
    }
    // ----------------- SET ANSWER TO ARRAY ANSWERS -------------------------
    setArrAnswers([...arrAnswers, answer]);
    // ----------------- SET CORRECT ANSWER TO RESULT -----------------
    if (arrNumb[0].correct_answer === 1) {
      setCountResult([...countResult, 1]);
    }
    // ----------------- SET ALL ANSWERS TO INFO ARRAY ----------------
    const allInfo = [...info];
    arrNumb.map((a) =>
      allInfo.push({ answer_id: a.id, question_id: a.question_id })
    );
    setInfo(allInfo);
    sessionStorage.setItem("answers", JSON.stringify(allInfo));
    setAnswer("");
    // ------------------- SENT RESULT ---------------
    if (allInfo?.length === commonQuest?.length) {
      if (arrNumb[0].correct_answer === 1) {
        sessionStorage.setItem(
          "result",
          JSON.stringify({
            result: [...countResult, 1],
            count: commonQuest?.length,
          })
        );
      }
      const res = JSON.parse(sessionStorage.getItem("result"));
      console.log(res);
      sessionStorage.setItem(
        "current",
        JSON.stringify({
          number: 1,
          id: commonQuest[0].id,
        })
      );
      let leftTime = 20 * 60 - counter;
      location.push("/result");
      sendResult({
        info: {
          type: general.type,
          theme_id: general.theme,
          result: res.result.length,
          time: leftTime,
          data_question: JSON.stringify(allInfo),
          count_question: commonQuest?.length,
        },
        token,
      });
    }
  };

  //   ---------------- KEEP TRACK TIMER ----------------
  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(function () {
        if (general?.type === "all_thema") {
          return () => clearInterval(timer);
        }
        if (info?.length === commonQuest?.length) {
          return () => clearInterval(timer);
        }
        let seconds = counter % 60;
        let minuts = (counter / 60) % 60;
        let strTimer = `${
          minuts < 10 ? `0${Math.trunc(minuts)}` : Math.trunc(minuts)
        }:${seconds < 10 ? `0${seconds}` : seconds}`;
        setCounterText(strTimer);
        sessionStorage.setItem("timer", JSON.stringify(counter));
        setCounter(counter - 1); // Уменьшаем таймер
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  // --------------- KEEP TRACK FINISHED TIME ----------------
  useEffect(() => {
    if (counter === 0) {
      location.push("/result");
      sendResult({
        info: {
          type: general.type,
          theme_id: general.theme,
          result: countResult.length,
          time: "20:00",
          data_question: JSON.stringify(info),
          count_question: commonQuest?.length,
        },
        token,
      });
    }
  }, [counter]);

  // -------------------- CHECK TIMER -----------------
  useEffect(() => {
    if (currentTime) {
      setCounter(currentTime);
    }
  }, []);
  // ------------------ CHECK ANSWERS ---------------
  useEffect(() => {
    if (currentAnswers) {
      setInfo(currentAnswers);
      const arr = [];
      currentAnswers.map((c) => arr.push(c.answer_id));
      setArrAnswers(arr);
    }
  }, []);
  // ------------- CHECK RESULT -----------------
  useEffect(() => {
    if (currResSess) {
      setCountResult(currResSess.result);
    }
  }, []);
  // ------------ CHECK QUESTIONS COLORS -------------
  useEffect(() => {
    if (greenQ && !redQ) {
      setCorrectQuestions(greenQ);
      setUncorrectQuestions([]);
      setQuestArray(greenQ);
    } else if (redQ && !greenQ) {
      setCorrectQuestions([]);
      setUncorrectQuestions(redQ);
      setQuestArray(redQ);
    } else if (greenQ && redQ) {
      setCorrectQuestions(greenQ);
      setUncorrectQuestions(redQ);
      setQuestArray(greenQ.concat(redQ));
    }
  }, []);
  // --------------- GET QUESTIONS -----------------
  // window.onload = () => {
  //   if (questions === null && token) {
  //     getQuestionsFromSession(token);
  //   }
  // };
  useEffect(() => {
    if (questions === null && token) {
      getQuestionsFromSession(token);
    }
  },[]);
  // ------------- SET RESULT ----------------
  useEffect(() => {
    if (countResult && commonQuest) {
      sessionStorage.setItem(
        "result",
        JSON.stringify({ result: countResult, count: commonQuest?.length })
      );
    }
  }, [countResult]);

  const history = useHistory();
  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };
  // -------------- LOGOUT FUNCTION ----------------
  const logoutUser = () => {
    onLogout(token);
    history.push("/");
  };
  // ------------- SET SWIPER ----------------------
  const setThumbSwiper = () => {};

  return (
    <div className="row">
      <Sidebar />
      <div class="content">
        <div class="content__inner">
          <header class="header">
            <div class="header__name page-block">
              <p class="header__text">Зараз здає:</p>
              <p class="header__highliht">
                {`${user?.first_name} ${user?.last_name}`}
              </p>
            </div>
            <div class="header__progress">
              <p class="header__text">
                Пройдено на{" "}
                <span class="header__percent">
                  {Math.round((arrAnswers?.length * 100) / commonQuest?.length)}
                  %
                </span>
              </p>
              <div class="header__line">
                <span
                  style={{
                    width: `${Math.round(
                      (arrAnswers?.length * 100) / commonQuest?.length
                    )}%`,
                  }}
                  class="header__line-inner"
                ></span>
              </div>
              <div class="header__progress-number">
                <span class="header__number-current">{currNumb}</span>/
                {commonQuest?.length}
              </div>
            </div>
            <div class="header__timer page-block">
              {general?.type === "all_thema" ? (
                <p class="header__text">Таймер виключено</p>
              ) : (
                <>
                  <p class="header__text">Залишилось часу:</p>
                  <span class="time">{counterText}</span>
                </>
              )}
            </div>
          </header>
          <main class="test page-block">
            <form>
              <h1 class="test__title title">Питання {currNumb}</h1>
              <div class="test__item">
                <p class="test__question">{currQuest?.question}</p>
                <div class="test__img">
                  {currQuest?.image?.length > 0 && (
                    <img
                      src={`http://backend.pdr.in.ua/images/question/${currQuest?.image}`}
                      alt="road sign"
                    />
                  )}
                </div>
              </div>
              <div class="test__inputs">
                {currQuest &&
                  currQuest?.answers?.map((t, index) => {
                    return (
                      <div class="test__radio" key={index}>
                        <input
                          class="visually-hidden test__input"
                          type="radio"
                          name="answers"
                          id={t.answer}
                          checked={arrAnswers.includes(t.id) || t.id === answer}
                          onChange={() => onChangeAnswers(t)}
                          value={answer}
                          disabled={info?.length === commonQuest?.length}
                        />
                        <label
                          htmlFor={t.answer}
                          className={`input__radio ${
                            arrAnswers?.includes(t.id)
                              ? t.correct_answer === 1
                                ? "footer__btn--true"
                                : "footer__btn--false"
                              : null
                          } ${
                            info?.length === commonQuest?.length
                              ? t.correct_answer === 1
                                ? "footer__btn--true"
                                : null
                              : null
                          }`}
                        >
                          {t.answer}
                        </label>
                      </div>
                    );
                  })}
              </div>
              {info?.length === commonQuest?.length ? (
                <div
                  className="result-btn-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <NavLink
                    to="/"
                    onClick={resetTest}
                    style={{ margin: "0 15px" }}
                  >
                    <button class="btn register__btn">На головну</button>
                  </NavLink>
                  <p onClick={logoutUser}>
                    <button class="btn register__btn">Вийти з програми</button>
                  </p>
                </div>
              ) : (
                <button
                  class="btn test__btn"
                  onClick={checkAswer}
                  disabled={!answer}
                >
                  Далі
                </button>
              )}
            </form>
          </main>

          <footer class="footer">
            <nav class="footer__nav">
              {commonQuest &&
                commonQuest?.map((n, index) => {
                  return (
                    <div className="test__radio number_radio" key={index}>
                      <input
                        class="visually-hidden test__input"
                        type="radio"
                        name="number"
                        id={n.id}
                        checked={number == n.id}
                        value={number}
                        onChange={() => onChangeQuestion(n.id, index)}
                      />
                      <label
                        htmlFor={n.id}
                        class={`footer__btn ${
                          correctQuestions?.includes(n.id)
                            ? "footer__btn--true"
                            : uncorrectQuestions?.includes(n.id)
                            ? "footer__btn--false"
                            : null
                        }`}
                      >
                        {index + 1}
                      </label>
                    </div>
                  );
                })}
            </nav>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Test;
