import { all, takeLatest } from "redux-saga/effects";
import types from "../redux/types/index";
// ------------ IMPORT WATCHERS -------------------
import { watchLogin, watchLogout, watchGetUser, watchDemoLogin } from "./auth";

import { watchGetThemes } from "./general";

import { watchSendResult } from "./result";

import { watchGetRidesData, watchGetRidesCalendar, watchRideSend } from "./ride";

import {
  watchGetQuestions,
  watchGetAllQuestions,
  watchGetExamQuestions,
  watchGetQuestionsFromSession,
  watchGetCrypt,
} from "./test";

import { watchGetTests } from "./myTests";

export function* rootSaga() {
  yield all([
    takeLatest(types.auth.LOGIN_REQUEST, watchLogin),
    takeLatest(types.auth.LOGOUT_REQUEST, watchLogout),
    takeLatest(types.auth.GET_USER_REQUEST, watchGetUser),

    takeLatest(types.auth.DEMO_LOGIN_REQUEST, watchDemoLogin),


    takeLatest(types.general.GET_THEMES_REQUEST, watchGetThemes),

    takeLatest(types.result.SEND_RESULT_REQUEST, watchSendResult),

    takeLatest(types.ride.GET_RIDES_DATA_REQUEST, watchGetRidesData),
    takeLatest(types.ride.GET_RIDES_CALENDAR_REQUEST, watchGetRidesCalendar),
    takeLatest(types.ride.SEND_RIDES_REQUEST, watchRideSend),

    takeLatest(types.test.GET_QUESTIONS_REQUEST, watchGetQuestions),
    takeLatest(types.test.GET_ALL_QUESTIONS_REQUEST, watchGetAllQuestions),
    takeLatest(types.test.GET_EXAM_QUESTIONS_REQUEST, watchGetExamQuestions),
    takeLatest(
      types.test.GET_QUESTIONS_FROM_SESSION_REQUEST,
      watchGetQuestionsFromSession
    ),
    takeLatest(types.test.GET_CRYPT_REQUEST, watchGetCrypt),

    takeLatest(types.myTests.GET_TESTS_REQUEST, watchGetTests),
  ]);
}
