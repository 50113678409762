import React from "react";
import "../assets/style/global.scss";
import MainHOC from "./routerHOC";
import Popup from "./hoc/popup";

const App = (props) => {
  return (
    <>
      <Popup />
      <MainHOC {...props} />
    </>
  );
};
export default App;
