import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetRidesData ({ payload }) {
  const response = yield API.ride.getRideData(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося отримати дані"));
    } else {
      yield put(Actions.ride.getRidesDataSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося отримати дані"));
  }
}
export function* watchGetRidesCalendar ({ payload, userId }) {
  const response = yield API.ride.getCalendar(payload, userId);
  if (response.status === 200) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Не вдалося отримати дані"));
    } else {
      yield put(Actions.ride.getRidesCalendarSuccess(response.data));
    }
  } else {
    yield put(Actions.popup.messageError("Не вдалося отримати дані"));
  }
}

export function* watchRideSend({ payload, token }) {

    const response = yield API.ride.sendRide(payload, token);
    if (response.status === 200) {
      if (response.data.status === "error") {
        yield put(Actions.popup.messageError("Помилка відправки"));
      } else {
        yield put(Actions.ride.sendRideSuccess());
      }
    } 
  }