import React from 'react';
import accept from '../../../../assets/img/accept.svg';


const ThanksMessage = ({ title , text }) => {
    return (
        <div className="thanks-msg">
            <div className="thanks-msg__icon">
                <img src={accept} alt="accept icon" />
            </div>
            <h1 className="thanks-msg__title"> {title}</h1>
            <span className="thanks-msg__descr">{text}</span>

        </div>
    )
}

export default ThanksMessage;