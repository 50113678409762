import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers/index";
import { rootSaga } from "../saga/index";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  window.__REDUX__DEVTOOLS__EXTENSION__COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export default store;
