import React from "react";
import { connect } from "react-redux";
import DemoAuth from "../components/DemoAuth/DemoAuth";
import Actions from "../redux/actions/index";

export const DemoAuthHOC = (props) => {
  return <DemoAuth {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  onDemoLogin: (data) => dispatch(Actions.auth.demoLoginRequest(data)),
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  setLogin: (data) => dispatch(Actions.auth.loginSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DemoAuthHOC);
