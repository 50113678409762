import React, { useEffect } from "react";
import { Redirect } from "react-router";
import Sidebar from "../../hoc/sidebar";
import css from "./MyTests.module.sass";

const MyTests = ({ token, my_tests, onGetTests}) => {
    // --------------- GET USER --------------------------------
    const user = JSON.parse(sessionStorage.getItem("user"));
  // -------------- GET TESTS -----------
  useEffect(() => {
    onGetTests(token);

  }, []);

  return (
    <div className="row">
      <Sidebar />
      <main class="content">
        <div class="content__inner">
          {my_tests ? (
            <>
              <div className="general">
                <forma class="general__choose">
                  <div class="general__choose__list">
                    {user && `${user.first_name} ${user.last_name}`}
                  </div>
                </forma>
              </div>
              <div className="page-block table__tests">
                <table id={css.customers}>
                  <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th>Тип</th>
                    <th>Тема</th>
                    <th>Результат</th>
                    <th>Час</th>
                  </tr>
                  {my_tests &&
                    my_tests.map((m, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{m.date}</td>
                          <td>{`${
                            m.type === "exam"
                              ? "Іспит"
                              : m.type === "theme"
                              ? "По темі"
                              : "Всі запитання по темі"
                          }`}</td>
                          <td>{m.theme?.title}</td>
                          <td>{`${m.result} / ${m.count_question}`}</td>
                          <td>{m.time}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </>
          ) : (
            <div className="empty__tests">
              <div className="page-block empty__tests__container">
                <h2>Ви ще не здавали жодного тесту</h2>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default MyTests;

