import React from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar/Sidebar";
import Actions from "../redux/actions/index";

export const SiderHOC = (props) => {
  return <Sidebar {...props} />;
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  // ------------------- RESET RESULTS -------------------------
  onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
  onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),
  onSetAllQuestions: (data) =>
    dispatch(Actions.test.getAllQuestionsSuccess(data)),
  onSetExamQuestions: (data) =>
    dispatch(Actions.test.getExamQuestionsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiderHOC);
