import React, { useEffect, useState } from "react";
import Sidebar from "../../hoc/sidebar";
import { Formik, Field as FieldFormik } from "formik";
import Field from "../common/Filed/Field";
import { validateTel, validateSelect, validateText } from "../../validators/validators";
import ThanksMessage from "../common/ThanksMessage/ThanksMessage";
import { Redirect, useHistory } from "react-router";

const Ride = ({
    token,
    isSend,
    isFetching,
    getData,
    getCalendar,
    categories,
    instructors,
    calendar,
    resetData,
    onSend,
}) => {
    // --------------- GET USER --------------------------------
    const user = JSON.parse(sessionStorage.getItem("user"));
    const your_name = `${user.first_name} ${user.last_name}`;
    // ----------------- SET LOCAL STATE ------------------
    const [day, setDay] = useState('');
    const [time, setTime] = useState('');
    const [time_id, setTime_id] = useState('');
    const [toggleSchedule, setToggleSchedule] = useState(false);

    // ----------------- SET HISTORY ------------------
    const history = useHistory(null);

    // -------------------- GET DATA ------------------
    useEffect(() => {
        getData(token)
    }, [])

    // -------------------- GET CALENDAR DATA ------------------
    const onConstructorSelect = (userId) => {
        if (userId !== '') {
            getCalendar(token, userId);
        }
    };
    // -------------------- DELETE DATA AFTER UN MOUNTING ------------------
    useEffect(() => {
        return () => {
            resetData()
        }
    }, []);

    // ----------------- TRACK ACTIVE ITEM ------------------
    const onTimeClick = (e, day, format, time_id, time) => {
        let items = document.querySelectorAll('.schedule__item');
        items.forEach(i => i.classList.remove('schedule__item-active'));
        e.target.classList.add('schedule__item-active');

        setToggleSchedule(true)
        setDay(`${day} ${format}`);
        setTime(time);
        setTime_id(time_id);

    };
    // ----------------- SUBMIT FORM ------------------
    const onSubmitForm = ({ phone, category_id, instructor_id }) => {
        onSend({
            your_name,
            phone,
            category_id,
            instructor_id,
            day,
            time,
            time_id

        }, token)
    }
    // -------------------- REDIRECT TO THE MAIN PAGE AFTER SENDING FORM ------------------
    useEffect(() => {
        if (isSend) {
            setTimeout(() => {
                history.push('/');
            }, 2000)
        }
    }, [isSend])

    if (!token) return <Redirect to="/" />

    return (
        <div className="row">
            <Sidebar />
            <div className="content">
                <div >
                    <div class="content__inner">
                        {
                            isSend
                                ? <ThanksMessage title={`${user.first_name}, дякуємо!`} text="Ваша заявка відправлена!" />

                                : <>
                                    <h1 className="general__choose__list">{your_name}</h1>
                                    <h1 className="register__title title">Записатися на <span>їзду</span></h1>
                                    <p className="register__description">Заповніть форму для запису</p>
                                    <Formik
                                        initialValues={{
                                            phone: "",
                                            category_id: 0,
                                            instructor_id: 0,
                                            time: ''
                                        }}
                                        onSubmit={(values,) => onSubmitForm(values)}>

                                        {({ errors, touched, setFieldValue, ...props }) => (

                                            <form className="ride ride-block" onSubmit={props.handleSubmit}>

                                                <FieldFormik
                                                    className="input__select ride__input"
                                                    type="tel"
                                                    name="phone"
                                                    text="номер телефону"
                                                    id="phone"
                                                    value={props.values.phone}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    mask="+380 (111) 111 111"
                                                    component={Field}
                                                    validate={validateTel}
                                                    errors={errors.phone}
                                                    touched={touched.phone} />


                                                <FieldFormik
                                                    className="input__input input__select"
                                                    name="category_id"
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur}
                                                    as="select"
                                                    component="select"
                                                    validate={validateSelect}>

                                                    <option selected hidden > Оберіть категорію</option>
                                                    {categories &&
                                                        categories.map((c, i) => {
                                                            return <option key={i} value={c.id} >{c.title}</option>
                                                        })
                                                    }

                                                </FieldFormik>

                                                {errors.category_id && touched.category_id
                                                    ? <div className={"field__error"}>{errors.category_id}</div>
                                                    : null}

                                                <FieldFormik
                                                    className="input__input input__select"
                                                    name="instructor_id"
                                                    onChange={props.handleChange}
                                                    as="select"
                                                    component="select"
                                                    validate={validateSelect}
                                                    onClick={() => onConstructorSelect(props.values.instructor_id)}
                                                >

                                                    <option selected hidden > Оберіть інструктура</option>
                                                    {instructors &&
                                                        instructors.map(i => {
                                                            return <option value={i.id}>{i.first_name} {i.last_name} </option>
                                                        })
                                                    }

                                                </FieldFormik>

                                                {errors.instructor_id && touched.instructor_id
                                                    ? <div className={"field__error"}>{errors.instructor_id}</div>
                                                    : null}



                                                <div className="schedule">
                                                    {calendar &&
                                                        calendar.map((s, i) => {
                                                            return <div className="schedule__col" key={i}>
                                                                <span className="schedule__title">
                                                                    {s.day} {s.format}
                                                                </span>
                                                                <ul className="schedule__list">
                                                                    {s.items.map((t, i) => {
                                                                        return <li onClick={t.status !== 1 ? (e) => onTimeClick(e, s.day, s.format, t.time_id, t.time) : null}
                                                                            data-attr={t.time_id}
                                                                            key={i}
                                                                            onChange={props.handleChange}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.time}
                                                                            className={`schedule__item ${t.status == 1 ? 'schedule__item-disable' : ''}`}> {t.time}</li>
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        })}

                                                </div>
                                                {
                                                    calendar &&

                                                    !toggleSchedule && <div className={"field__error"}>Оберіть дату</div>
                                                }

                                                <div className="ride__footer">
                                                    <button disable={toggleSchedule && props.dirty} className="btn register__btn ride__btn" type="submit">
                                                        Записатися
                                                    </button>
                                                    {/* {isFetching && 
                                                        <div className="ride__spinner">
                                                            <img src={spinner} alt="spinner" />
                                                        </div>} */}
                                                </div>
                                            </form>
                                        )}
                                    </Formik> </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Ride;