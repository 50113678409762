import { combineReducers } from "redux";
import auth from "./auth";
import general from "./general";
import test from "./test";
import myTests from "./myTests";
import popup from "./popup";
import ride from "./ride"

const rootReducer = combineReducers({
  auth,
  general,
  test,
  myTests,
  popup,
  ride
});

export default rootReducer;
