import React from 'react';
import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import { LastLocationProvider,useLastLocation } from 'react-router-last-location';
import AuthPage from './pages/auth';
import TestPage from './pages/test';
import ResultPage from './pages/result';
import GeneralPage from './pages/general';
import MyTestsPage from './pages/myTests';
import RidePage from './pages/ridePage';
import DemoAuthPage from './pages/demoAuth';

export const Main = ({ token }) => {
  // ------------- SET HISTORY --------------
  const history = useHistory(null);

  const t = sessionStorage.getItem("token")

  if (!token || !t) {
    return (
      <LastLocationProvider>
        <Switch>
          <Route exact={t ? false : true}  path="/" component={AuthPage} />
          <Route exact path="/demo" component={DemoAuthPage} />
        </Switch>
      </LastLocationProvider>
    )
  } else if (history.location.pathname === "/demo" && t){
    history.push("/")
  }

  return (
    <LastLocationProvider>
      <Switch>
        <Route exact path="/test" component={TestPage} />
        <Route exact path="result" component={ResultPage} />
        <Route exact path="/" component={GeneralPage} />
        <Route exact path="/my_test" component={MyTestsPage} />
        <Route exact path="/ride" component={RidePage} />
      </Switch>
    </LastLocationProvider>
  );
};
