import React from "react";
import { connect } from "react-redux";
import Test from "../components/Test/Test";
import Actions from "../redux/actions/index";

export const TestHOC = (props) => {
  return <Test {...props} />;
};

const mapStateToProps = (state) => ({
  questions: state.test.questions,
  allQuestions: state.test.allQuestions,
  examQuestions: state.test.examQuestions,
  token: state.auth.token,
  user: state.auth.user,
  crypt: state.test.crypt,
});

const mapDispatchToProps = (dispatch) => ({
  sendResult: (data) => dispatch(Actions.result.sendResultRequest(data)),
  getQuestionsFromSession: (data) =>
    dispatch(Actions.test.getQuestionsFromSessionRequest(data)),
  onLogout: (data) => dispatch(Actions.auth.logoutRequest(data)),
  // ------------------- RESET RESULTS -------------------------
  onSetThemes: (data) => dispatch(Actions.general.getThemesSuccess(data)),
  onSetCurrentTheme: (data) => dispatch(Actions.general.currentTheme(data)),
  onSetQuestions: (data) => dispatch(Actions.test.getQuestionsSuccess(data)),
  onGetCrypt: (data) => dispatch(Actions.test.getCryptRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TestHOC);
