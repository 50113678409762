import { put } from "redux-saga/effects";
import { API } from "../api/index";
import Actions from "../redux/actions/index";

export function* watchGetQuestions({ payload }) {
  const response = yield API.test.getQuestions(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка"));
    } else {
      const decrypt = yield JSON.parse(window.atob(response.data));
      yield put(Actions.test.getQuestionsSuccess(decrypt));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка"));
  }
}

export function* watchGetAllQuestions({ payload }) {
  const response = yield API.test.getAllQuestions(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка"));
    } else {
      const decrypt = yield JSON.parse(window.atob(response.data));
      yield put(Actions.test.getQuestionsSuccess(decrypt));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка"));
  }
}

export function* watchGetExamQuestions({ payload }) {
  const response = yield API.test.getExamQuestions(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка"));
    } else {
      const decrypt = yield JSON.parse(window.atob(response.data));
      yield put(Actions.test.getQuestionsSuccess(decrypt));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка"));
  }
}

export function* watchGetQuestionsFromSession({ payload }) {
  const response = yield API.test.getQuestionsFromSession(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка"));
    } else {
      const decrypt = yield JSON.parse(window.atob(response.data));
      yield put(Actions.test.getQuestionsSuccess(JSON.parse(decrypt)));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка"));
  }
}

export function* watchGetCrypt({ payload }) {
  const response = yield API.test.getCrypt(payload);

  if (response.status === 200 || response.status === 201) {
    if (response.data.status === "error") {
      yield put(Actions.popup.messageError("Помилка"));
    } else {
      const decrypt = yield JSON.parse(window.atob(response.data));
      yield put(Actions.test.getQuestionsSuccess(decrypt));
    }
  } else {
    yield put(Actions.popup.messageError("Помилка"));
  }
}
