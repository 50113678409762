import types from "../types/index";

const initialState = {
    categories: null,
    instructors: null,
    calendar: null,
    isSend: false,
    isFetching: false
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.ride.GET_RIDES_DATA_SUCCESS:
            return {
                ...state,
                categories: payload.categories,
                instructors: payload.instructors
            };
        case types.ride.GET_RIDES_CALENDAR_SUCCESS:
            return {
                ...state,
                calendar: payload
            }
        case types.ride.SEND_RIDES_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case types.ride.SEND_RIDES_SUCCESS:
            return {
                ...state,
                isSend: true,
                isFetching: false
            };
        case types.ride.RESET_RIDES_DATA:
            return {
                ...state,
                calendar: null,
                isSend: false,
            };
        default:
            return state;
    }
};

export default reducer;
