import types from "../types/index";

const getThemesRequest = (payload) => ({
  type: types.general.GET_THEMES_REQUEST,
  payload,
});

const getThemesSuccess = (payload) => ({
  type: types.general.GET_THEMES_SUCCESS,
  payload,
});

const currentTheme = (payload) => ({
  type: types.general.CURRENT_THEME,
  payload
})

export default {
  getThemesRequest,
  getThemesSuccess,
  currentTheme
};
