import React from "react";
import { HashRouter,  browserHistory } from 'react-router-dom';

import { connect } from "react-redux";
import { Main } from "./router";

export const MainHOC = (props) => {
  return <HashRouter  path="/">
    <Main {...props} />;
  </HashRouter>
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps, null)(MainHOC);
