import axios from "axios";
import { auth } from "./auth";
import { demoAuth } from './demoAuth';
import { general } from "./general";
import { test } from "./test";
import { result } from "./result";
import { myTests } from "./myTests";
import {ride} from "./ride"
const URL = "http://softdeal.beget.tech/api";

export const axiosInstance = axios.create({
  baseURL: URL,
});

export const API = { auth, demoAuth, general, test, result, myTests, ride};
