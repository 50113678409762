import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../../hoc/sidebar";
import Field from "../common/Filed/Field";
import { Formik, Field as FieldFormik } from 'formik';
import { validateEmail, validateText } from "../../validators/validators";


const Auth = ({ onLogin, token, setLogin }) => {
  // ----------------- LOGIN USER -------------------------------
  const loginUser = ({ email, password }) => {
    onLogin({
      email,
      password
    });
  };
  // // ----------- KEEP TRACK LOGIN --------------
  useEffect(() => {
    if (!token) {
      setLogin(sessionStorage.getItem("token"));
    }
  }, [token]);

  return (
    <div className="row">
      <Sidebar />
      <main className="content">
        <div className="content__inner jc-center">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values) => loginUser(values)}>

            {({ errors, touched, ...props }) => (

              <form className="register page-block" onSubmit={props.handleSubmit}>
                <h1 className="register__title title">ПДР онлайн-тест</h1>
                <p className="register__description">Авторизуйтесь в системі</p>
                <FieldFormik
                  type="email"
                  name="email"
                  text="email"
                  id="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  component={Field}
                  validate={validateEmail}
                  errors={errors.email}
                  touched={touched.email}
                />

                <FieldFormik
                  type="password"
                  name="password"
                  text="пароль"
                  id="password"
                  value={props.values.password}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  component={Field}
                  validate={validateText}
                  errors={errors.password}
                  touched={touched.password}
                />
                <a>
                  <button className="btn register__btn" type="submit">
                    Увійти
                  </button>
                </a>
                <NavLink to="/demo">
                  <button disable={props.dirty}
                    className="btn register__btn-demo" type="submit">
                    отримати ДЕМО доступ
                  </button>
                </NavLink>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
};

export default Auth;
