import types from "../types/index";

const getRidesDataRequest = (payload) => ({
  type: types.ride.GET_RIDES_DATA_REQUEST,
  payload,
});

const getRidesDataSuccess = (payload) => ({
  type: types.ride.GET_RIDES_DATA_SUCCESS,
  payload
});

const getRideCalendarRequest = (payload, userId) => ({
  type: types.ride.GET_RIDES_CALENDAR_REQUEST,
  payload,
  userId
});

const getRidesCalendarSuccess = (payload) => ({
  type: types.ride.GET_RIDES_CALENDAR_SUCCESS,
  payload,
});

const resetRidesCalendarSuccess = () => ({
  type: types.ride.RESET_RIDES_DATA,
});


const sendRideRequest = (payload, token) => ({
  type: types.ride.SEND_RIDES_REQUEST,
  payload,
  token
});

const sendRideSuccess = () => ({
  type: types.ride.SEND_RIDES_SUCCESS,
});
export default {
  getRidesDataRequest,
  getRidesDataSuccess,
  getRideCalendarRequest,
  getRidesCalendarSuccess,
  resetRidesCalendarSuccess,
  sendRideRequest,
  sendRideSuccess,
};
