import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";

const Sidebar = ({
  onLogout,
  token,
  onSetThemes,
  onSetCurrentTheme,
  onSetQuestions,
}) => {
  // ------------- GET HISTORY -------------------
  const history = useHistory(null);
  // ------------ SET LOCAL STATE -----------------
  const [active, setActive] = React.useState(false);
  // -------------- GET TIME --------------------
  const currentTime = JSON.parse(sessionStorage.getItem("timer"));
  const general = JSON.parse(sessionStorage.getItem("general"));
  // -------------- LOGOUT FUNCTION ----------------
  const logoutUser = () => {
    onLogout(token);
    history.push("/");
  };
  // ------------- RESET TEST -------------
  const resetTest = () => {
    onSetThemes(null);
    onSetCurrentTheme(null);
    onSetQuestions(null);
    sessionStorage.removeItem("timer");
    sessionStorage.removeItem("answers");
    sessionStorage.removeItem("current");
    sessionStorage.removeItem("result");
    sessionStorage.removeItem("redQ");
    sessionStorage.removeItem("greenQ");
    sessionStorage.removeItem("general");
  };
  // let location = useLocation()
  const [counter, setCounter] = useState(20 * 60);
  const [counterText, setCounterText] = useState(null);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(function () {
        if (history?.location?.pathname !== "/test") {
          return () => clearInterval(timer);
        }
        if (general?.type === "all_thema") {
          return () => clearInterval(timer);
        }
        let seconds = counter % 60;
        let minuts = (counter / 60) % 60;
        let strTimer = `${minuts < 10 ? `0${Math.trunc(minuts)}` : Math.trunc(minuts)
          }:${seconds < 10 ? `0${seconds}` : seconds}`;
        setCounterText(strTimer);
        setCounter(counter - 1); // Уменьшаем таймер
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  // -------------------- CHECK TIMER -----------------
  useEffect(() => {
    if (currentTime) {
      setCounter(currentTime);
    }
  }, []);

  return (
    <>
      <div className="burger__wrapper">
        <button
          aria-label="Відкрити меню"
          title="Відкрити меню"
          className={`burger ${active ? "active" : ""}`}
          onClick={() => setActive(!active)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {history?.location?.pathname === "/test" && (
          <div className="time">{counterText}</div>
        )}
        <div className="burger__logo">
          Віктор<span className="red">іЯ</span>
        </div>
      </div>
      <aside className={`aside ${active ? "active" : ""}`}>
        <div className="aside__inner">
          <nav className="aside__menu menu">
            <ul className="menu__list">
              <li className="menu__item">
                <div className="menu__logo">
                  Віктор<span className="red">іЯ</span>
                </div>
              </li>
              {token ? (
                <>
                  {history.location.pathname === "/" && (
                    <><li className="menu__item">
                      <NavLink to="/my_test" className="menu__link">
                        Мої тести
                      </NavLink>
                    </li><li className="menu__item">
                        <NavLink to="/ride" className="menu__link">
                          Запис на їзду
                        </NavLink>
                      </li></>
                  )}
                  {history.location.pathname === "/test" && (
                    <li className="menu__item" onClick={resetTest}>
                      <NavLink to="/" className="menu__link">
                        Завершити тест
                      </NavLink>
                    </li>
                  )}
                  {history.location.pathname === "/my_test" && (
                    <><li className="menu__item">
                      <NavLink to="/" className="menu__link">
                        Пройти тести
                      </NavLink>
                    </li>
                      <li className="menu__item">
                        <NavLink to="/ride" className="menu__link">
                          Запис на їзду
                        </NavLink>
                      </li></>
                  )}
                  {history.location.pathname === "/ride" && (
                    <><li className="menu__item">
                    <NavLink to="/my_test" className="menu__link">
                      Мої тести
                    </NavLink>
                  </li><li className="menu__item">
                      <NavLink to="/ride" className="menu__link">
                        Запис на їзду
                      </NavLink>
                    </li></>
                  )}
                  <li className="menu__item" onClick={logoutUser}>
                    <button className="menu__btn btn">Вийти</button>
                  </li>
                </>
              ) : null}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
