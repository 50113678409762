import types from "../types/index";


const messageSuccess = (payload) => ({
  type: types.popup.MESSAGE_SUCCESS,
  payload,
});

const messageError = (payload) => ({
  type: types.popup.MESSAGE_ERROR,
  payload,
});

export default {
  messageSuccess,
  messageError,
};
