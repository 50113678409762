import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../hoc/sidebar";
import { general as data } from "./data";

const General = ({
  onLogout,
  onGetThemes,
  themes,
  token,
  currentTheme,
  onCurrentTheme,
  onGetQuestions,
  onGetAllQuestions,
  onGetExamQuestions,
  user,
  onGetUser,
}) => {
  // ------------ LOCAL STATE FOR CONTROL RADIO_BUTTON ----------
  const [checked, setChecked] = useState(null);
  // ------------- SET HISTORY --------------
  const history = useHistory(null);
  // -------------- SET REF -----------------
  let refs = React.useRef([React.createRef(), React.createRef(), React.createRef()]);

  // -------------- LOGOUT FUNCTION ----------------
  const logoutUser = () => {
    onLogout(token);
  };
  // ------------------------- SET INITIAL THEMES --------------------
  useEffect(() => {
    if (token) {
      onGetThemes(token);
    }
  }, [token]);

  useEffect(() => {
    if (checked === 2 || checked === 3) {
      onCurrentTheme(1);
    }
    if(checked !== null) {
      refs.current[0].current.classList.remove("blob");
      refs.current[1].current.classList.remove("blob");
      refs.current[2].current.classList.remove("blob");
    }
  }, [checked]);

  // ---------------- GET USER ------------------------
  useEffect(() => {
    if (token) {
      onGetUser(token);
    }
  }, [token]);


  // --------------------- GET QUESTIONS ---------------------
  const getQuestions = () => {
    if (checked === null) {
      refs.current[0].current.classList.add("blob");
      refs.current[1].current.classList.add("blob");
      refs.current[2].current.classList.add("blob");
      return;
    }
    if (checked === 2) {
      onGetQuestions({
        id: { theme_id: Number(currentTheme) },
        token: token,
      });
    } else if (checked === 3) {
      onGetAllQuestions({
        id: { theme_id: Number(currentTheme) },
        token: token,
      });
    } else {
      onGetExamQuestions(token);
    }
    const type = checked === 1 ? "exam" : checked === 2 ? "theme" : "all_thema";
    sessionStorage.setItem(
      "general",
      JSON.stringify({ type: type, theme: currentTheme })
    );
    history.push('/test');
  };

  return (
    <div className="row">
      <Sidebar />
      <main className="content">
        <div className="content__inner">
          <div className="general">
            <div className="general__rules page-block">
              <ul className="general__rules__list">
                {data.rule.map((r) => {
                  return <li className="general__rules__item">{r}</li>;
                })}
              </ul>
            </div>
            <form className="general__choose">
              <p className="general__choose-text">
                Виберіть режим складання тестів:
              </p>
              <div className="general__choose__list">
                {data.type.map((t, i) => {
                  return (
                    <div className="test__radio general__choose__item" key={i}>
                      <input
                        className="visually-hidden test__input"
                        type="radio"
                        name={t.name}
                        id={t.id}
                        onChange={() => setChecked(t.id)}
                        checked={checked === t.id}
                      />
                      <label
                        htmlFor={t.id}
                        className="input__radio"
                        ref={refs.current[i]}
                      >
                        {t.label}
                      </label>
                    </div>
                  );
                })}
              </div>
              {(checked === 2 || checked === 3) && (
                <div className="general__theme">
                  <label className="general__theme__label">Тема</label>
                  <select
                    className="general__theme__list"
                    onChange={(e) => {
                      onCurrentTheme(e.target.value);
                    }}
                  >
                    {themes &&
                      themes?.map((t,i) => {
                        if (user.role_id === 5) {
                          if(i <= 1) {
                            return (
                              <option
                                className="general__theme__item"
                                value={t.id}
                                disabled={false}
                              >
                                {t.title}
                              </option>
                            );
                          } else {
                            return (
                              <option
                                className="general__theme__item"
                                value={t.id}
                                disabled={true}
                              >
                                {t.title}
                              </option>
                            );
                          }
                        }
                        else {
                          return (
                            <option
                              className="general__theme__item"
                              value={t.id}
                              disabled={false }
                            >
                              {t.title}
                            </option>
                          );
                        }
                      })}
                  </select>
                </div>
              )}
            </form>

            <div className="general__btns">
              <button
                className="general__start__btn btn"
                onClick={getQuestions}
              >
                Згоден. Розпочати екзамен
              </button>
              <a href="#">
                <button className="general__exit__btn btn" onClick={logoutUser}>
                  Вийти з програми
                </button>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default General;
