export const general = {
  type: [
    { label: "Всі запитання по темі", name: "type", id: 3 },
    { label: "Залік по темі", name: "type", id: 2 },
    { label: "Іспит", name: "type", id: 1 },
  ],
  rule: [
    `Час на складання іспиту становить 20 хв, при цьому немає
    ніяких пауз та перерв.`,
    `Відлік часу розпочинається після натискання кнопки "Розпочати
    екзамен".`,
    `Кожний екзаменаційний білет вміщує 20 питань, які мають
    порядкові номери від 1 до 20. На кожне питання дається 1 відповідь.`,
    `Під час іспиту Ви можете пропускати питання, на які важко
    відповісти, і повертатися до них знову.`,
    `Позитивна оцінка "склав" ставиться у випадку, якщо кандидат у
    водії відповів на всі екзаменаційні питання за відведений на
    це час та допустив не більше двох неправильних відповідей.
    Якщо кандидат у водії допустив три помилки у відповідях, іспит
    припиняється.`,
    `Особам, які у відведений час не відповіли на всі питання або
    допустили більше двох помилок, виставляється оцінка "не
    склав".`,
  ],
};
