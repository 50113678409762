import types from "../types/index";

const loginRequest = (payload) => ({
  type: types.auth.LOGIN_REQUEST,
  payload,
});

const demoLoginRequest = (payload) => ({
  type: types.auth.DEMO_LOGIN_REQUEST,
  payload,
});

const demoLoginSuccess = (payload) => ({
  type: types.auth.DEMO_LOGIN_SUCCESS,
  payload,
});


const loginSuccess = (payload,) => ({
  type: types.auth.LOGIN_SUCCESS,
  payload,
});

const logoutRequest = (payload) => ({
  type: types.auth.LOGOUT_REQUEST,
  payload,
});

const getUserRequest = (payload) => ({
  type: types.auth.GET_USER_REQUEST,
  payload,
});

const getUserSuccess = (payload) => ({
  type: types.auth.GET_USER_SUCCESS,
  payload,
});

export default {
  loginRequest,
  demoLoginRequest,
  demoLoginSuccess,
  loginSuccess,
  logoutRequest,
  getUserRequest,
  getUserSuccess,
};
