export default {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",

  DEMO_LOGIN_REQUEST: "DEMO_LOGIN_REQUEST",
  DEMO_LOGIN_SUCCESS: "DEMO_LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
};
